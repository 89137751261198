<template>
  <collapse-card :model-value="true">
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Licensee Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <base-input
          v-model="submissionForm.licenseeName"
          type="text"
          disabled
          container-class="w-1/2 mb-4 flex items-center pr-6"
          label="Licensee Name"
          label-class="label-w-46"
        />
        <base-input
          v-model="submissionForm.licenseeAddress"
          text-area
          rows="3"
          disabled
          container-class="w-full mb-4 flex"
          label="Licensee Address"
          label-class="label-w-46"
        />
        <div class="grid grid-cols-2 gap-x-12 gap-y-4 mb-4">
          <base-input
            v-model="submissionForm.contactName"
            type="text"
            disabled
            container-class="flex items-center"
            label="Contact Name"
            label-class="label-w-46"
          />
          <base-input
            v-model="submissionForm.contactPhone"
            type="text"
            disabled
            container-class="flex items-center"
            label="Contact Phone"
            label-class="label-w-46"
          />
          <base-input
            v-model="submissionForm.contactEmail"
            type="text"
            :disabled="!submissionForm.isAdminEdit"
            container-class="flex items-center"
            label="Contact Email"
            label-class="label-w-46"
          />
          <div>
            <base-button
              class="flex items-center"
              variant="btn-primary"
              size="py-1.5 px-2.5"
              text="Change Contact Email"
              type="button"
              @click="setContactModalVisibility(true)"
            />
          </div>
        </div>
        <base-input
          v-model="submissionForm.additionalEmail"
          type="text"
          container-class="w-full flex items-center"
          label="Additional Contact Email"
          label-class="label-w-46"
        />
      </div>
      <!-- Change Contact Modal -->
      <change-contact-modal
        v-model="contactModalVisibility"
        @hide="setContactModalVisibility(false)"
      />
    </template>
  </collapse-card>
</template>

<script>
import { defineAsyncComponent, ref } from 'vue';
import useSubmissionForm from './submissionForm.js';

export default {
    name: 'LicenseeDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        ChangeContactModal: defineAsyncComponent(() => import('./ChangeContactModal.vue'))
    },

    setup () {
        const { submissionForm } = useSubmissionForm();

        // change contact modal logic
        const contactModalVisibility = ref(false);
        const setContactModalVisibility = (visibility) => {
            contactModalVisibility.value = visibility;
        };

        return {
            submissionForm,
            // change contact modal
            contactModalVisibility,
            setContactModalVisibility
        };
    }
};
</script>
